import React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';

import { PropertyHit } from ".";

import { Grid3 } from "site/src/components/css";

var _ = require('lodash');
var property = [];

const SearchResults = ({ hits, type, isSearchStalled }) => {

  if (type === "ToLet") {
    property = Object.values(_.groupBy(hits, 'property_gmaven_key'));
  } else {
    property = hits;
  }

  //Loading
  if (isSearchStalled) {
    return (
      <div className="loader"></div>
    )
  }

  //Results
  if (property.length > 0 && !isSearchStalled) {

    return (

      <Grid3>

        {
          property.map((hit, i) => {

            return (

              <div key={i} className="flatGrid" >

                <PropertyHit
                  hitData={hit}
                  type={type}
                  i={i}
                />

              </div>

            )
          })
        }

      </Grid3>

    )
  }

  //No results
  if (property.length === 0 && !isSearchStalled) {
    return "Oops, we have no results that meet your search criteria. Please reset your filters and try again."
  }

}

export default connectHits(connectStateResults(SearchResults))