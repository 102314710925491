import React from 'react';
import { Link } from "gatsby";

import { InfoTab } from "site/src/components";

const { makePropertyPagePath } = require("../utils/urlGenerator")

function PropertyHit({ hitData, type, i }) {

  var property = hitData;

  if (type === "ToLet") {

    return (

      <div>

        <Link to={makePropertyPagePath(property[0], "ToLet", "property")} key={i}>

          <InfoTab info={property} deal="ToLet" type="property" i={i} />

        </Link>

      </div>

    )
  } else if (type === "ForSale") {
    return (

      <div>

        <Link to={makePropertyPagePath(property, "ForSale", property.property_type)} key={i}>

          <InfoTab info={property} deal="ForSale" type="property" i={i} />

        </Link>

      </div>

    )
  } else {
    return (
      <div>No results</div>
    )
  }

}

export default PropertyHit