import React, { Fragment } from 'react';

import { connectRefinementList } from 'react-instantsearch-dom';

import { Grid22 } from "site/src/components/css"

const HeroRefinementList = ({ items, refine }) => {

  return (

    <Fragment>

      {
        typeof window !== 'undefined' && (

          <Grid22 className="categoryFilter">
            {
              items.map((item, i) => (
                <div key={i}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      onChange={event => {
                        event.preventDefault();
                        refine(item.value);
                      }}
                      checked={item.isRefined}
                    />
                    <span className="checkbox-custom"></span>
                  </label>
                  <div className="input-title">{item.label.replace("VacantLand", "Vacant land")}</div>
                </div>
              ))
            }
          </Grid22>

        )
      }

    </Fragment>

  )
};

export default connectRefinementList(HeroRefinementList);