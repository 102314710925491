import React from 'react';

import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = ({ currentRefinement, refine }) => {
  return (

      <input
        id="searchBox"
        type="text"
        value={currentRefinement}
        onChange={event => {
          refine(event.currentTarget.value)
        }}
        placeholder="Search our properties"
        aria-label="searchBox"
      />

  )
};

export default connectSearchBox(SearchBox);