import React, { Fragment } from 'react';

import { connectSortBy } from 'react-instantsearch-dom';

import Switch from "react-switch";

const DealSwitch = connectSortBy(({ refine, items }) => {

  return (

    <Fragment>

      {
        typeof window !== 'undefined' && (

          <Switch
            onChange={() => refine(items.filter(item => !item.isRefined)[0].value)}
            checked={items[1].isRefined}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={process.env.GATSBY_PRIMARY_COLOUR}
            offColor="#fff"
            onHandleColor="#fff"
            offHandleColor={process.env.GATSBY_PRIMARY_COLOUR}
          />

        )
      }

    </Fragment>

  )

});

export default DealSwitch;